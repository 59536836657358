/* eslint-disable */
import axios from 'axios'
import store from '@/store'
import {SERVER_URL,DEVELOPMENT_SERVER_URL} from '@/config/server'
import {Notification, Message, Loading} from 'element-ui'
import router from '@/router';

// 正在进行中的请求列表
const reqList = [];

/**
 * 阻止重复请求
 * @param {array} reqList - 请求缓存列表
 * @param {string} url - 当前请求地址
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */
const stopRepeatRequest = function (reqList, url, cancel, errorMessage) {
  const errorMsg = errorMessage || ''
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      return
    }
  }
  reqList.push(url)
}
/**
 * 允许某个请求可以继续进行
 * @param {array} reqList 全部请求列表
 * @param {string} url 请求地址
 */
const allowRequest = function (reqList, url) {
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      reqList.splice(i, 1)
      break
    }
  }
}
let loadingInstance = null

export function request(config) {
  const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ?
      DEVELOPMENT_SERVER_URL : SERVER_URL,
    timeout: 60 * 1000
  })
  instance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (res) => {
      if (res.data.code == 400003) {
        window.localStorage.clear();
        router.push({
          path: '/baseLogin',
          query: {
            redirect: router.currentRoute.fullPath,
            ...store.state.query
          } // 从哪个页面跳转
        })
      }
      loadingInstance && loadingInstance.close()
      setTimeout(() => {
        allowRequest(reqList, res.config.url)
      }, 1000)
      return res
    },
    (error) => {
      Message.closeAll()
      if (error.response.status === 404) {
        return false;
      }
      if (axios.isCancel(error)) {
        console.log(error.msg)
      } else {
        // 增加延迟，相同请求不得在短时间内重复发送
        setTimeout(() => {
          allowRequest(reqList, error.config.url)
        }, 1000)
      }
      loadingInstance && loadingInstance.close()
      return Promise.reject(error)
    }
  )
  return instance(config)
}

// 合并多个请求
export function allRequest(arrRequest) {
  return axios.all(arrRequest)
}
