<template>
  <div class="home-container">
    <div class="bannerImg">
      <el-carousel :height="bannerHeight+'px'" :arrow="bannerlist.length>1?'hover':'never'">
        <el-carousel-item :key="index" v-for="(item,index) in bannerlist">
          <img :src="item.url" class="small" style="width: 100%;"/>
        </el-carousel-item>
      </el-carousel>
      <div @click="viewMore" class="search">
        <div class="search_back">
          <div class="search_input">
            <span>快速搜索 CAMP CENTER</span>
            <i class="el-icon-search"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="com-container home_content">
      <!-- 热门活动   -->
      <div>
        <div class="home_title">
          热门活动
        </div>
        <div class="active-list">
          <div :key="index" :style="{
        '-webkit-animation-delay': (index+1)*0.1+'s',
        'imation-delay': (index+1)*0.1+'s',
      }" class="active-list-item"
               v-for="(item, index) in activeList">
            <div class="inner">
              <div class="active-list-item-bg">
                <el-image :src="item.activityCover" fit="cover"></el-image>
              </div>
              <div class="active-list-item-cont">
                <div class="active-list-item-cont-header">
                  <div class="label" v-if="item.activityLabel">
                <span :key="index2"
                      v-for="(item2, index2) in item.activityLabel.split(',')">{{item2}}</span>
                  </div>
                  <div class="title">{{item.name}}</div>
                </div>
                <div class="active-list-item-cont-txt1">
                  <div class="sign-box" v-if="item.schoolName">
                    <div :key="index2"
                         class="sign"
                         v-for="(item2, index2) in item.schoolName.split(',')">{{item2}}
                    </div>
                  </div>
                  <div class="sign-box" v-if="item.subjectName">
                    <div :key="index2"
                         class="sign"
                         v-for="(item2, index2) in item.subjectName.split(',')">{{item2}}
                    </div>
                  </div>
                </div>
                <div class="active-list-item-cont-txt2">{{formatYMD(item.activityStartTime)}}
                  （共{{item.activityTime}}天）
                </div>
                <div class="active-list-item-cont-txt3">报名截止时间：{{formatYMDChina(item.bmEndTime)}}</div>
              </div>
              <div @click="toActive(item.id)" class="active-list-item-bottom">
                <div>查看详情</div>
                <i class="el-icon-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--查看更多-->
      <div class="flex-line">
        <div class="line"></div>
        <div @click="viewMore" class="view-more">查看更多</div>
      </div>
      <!-- 学生感想和体会   -->
      <div>
        <div class="home_title">
          学生感想和体会
        </div>
        <div class="reflectionList">
          <div :key="index"
               class="reflections_item" v-for="(item, index) in reflectionsList">
            <div class="item_float">
              <div class="item_float_content">
                <div class="item_name">
                  <div>
                    <div class="name">
                      {{item.name}}
                    </div>
                    <div class="name_line">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reflections_content">
              <div class="tags">
                <div :key="index2" class="tag" v-for="(item2, index2) in item.tag">
                  {{item2}}
                </div>
              </div>
              <div class="item_text">
                {{item.think}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import banner1 from "../../../assets/img/Banner/banner5.png";
  import {hot} from "r/base/home";

  export default {
    name: "Home",
    data() {
      return {
        bannerHeight: "",
        bannerlist: [
          {
            url: banner1
          }
        ],
        activeList: [],
        reflectionsList: [
          {
            name: '张同学',
            think: '通过这几天的学习我深深地感受到了历史的魅力。从延伸千年的丝绸之路到骁勇善战的草原大漠，前往神秘享测的古埃及，置身考古现场研究墓穴壁画的构造。三天的学习使我受益匪浅，了解到很多之前闻所未闻的知识。',
            tag: ['鄂尔多斯衡水实验中学', '2023南开历史营']
          },
          {
            name: '黄同学',
            think: '对于这次的夏令营我的收获很大，这次的夏令营，教授和博士们的精彩演讲内容是课本中学不到的，很大的程度上对我的帮助很大，开拓了知识面。从这次的夏令营动中，我学会了从不同的角度去看待问题。',
            tag: ['浙江省树人中学', '2023南开历史营']
          },
          {
            name: '李同学',
            think: '四天的学习生活让我获益匪浅，收获到了很多课本外的知识。经过本次学习我开拓了自己的眼界，激发了对历史学习的兴趣，也培养了与小组同学团结协作的能力。最后，感谢学科营的各位老师和同学，期待我们下一次相遇。',
            tag: ['东北育才学校', '2023南开历史营']
          },
          {
            name: '宁同学',
            think: '这个活动让我结识了很多厉害的学长，让我接触到了一些很厉害的教授，感受到了美丽数学，更收获了友谊，最关键的是增强了自我生活的能力，和团结合作的能力。',
            tag: ['北京市第五中学', '2023南开数学营']
          },
          {
            name: '储同学',
            think: '在这几天的学习里，我深切体会到了数学的如此魅力想向在此期间为我们提供帮助的教授们表达感谢感谢使我的前途更加明确，深刻的了解到了统计学和我们的生活息相关，对统计学产生了极大兴趣，感谢这次暑期营注动，在今后的高三学习里会更加努力，谢谢。',
            tag: ['博野中学', '2023南开数学营']
          },
          {
            name: '王同学',
            think: '在这几天的学习里，我对数学与统计学有了更深刻的认识，更充地认识到了二者在应用层面的价值，同时也拓宽了眼界，学到了课之外的知识。经过这三天的学习，我对自己的未来专业有了更加清的规划，也对未来的大学生活抱有更美好的憧憬。同时，我也想感谢各位教授与学长学姐的悉心教诲，感谢老师们的授业解惑。',
            tag: ['济宁市第一中学', '2023南开数学营']
          },
        ]
      };
    },
    mounted() {
      // 首次加载时,初始化高度
      this.screenWidth = window.innerWidth
      this.bannerHeight = 600 / 1550 * this.screenWidth
      // 窗口大小发生改变
      window.onresize = () => {
        this.screenWidth = window.innerWidth
        this.bannerHeight = 600 / 1550 * this.screenWidth
      }
    },
    created() {
      this.commonApi(39)
      this.getHot();
    },
    methods: {
      // 查看更多
      viewMore() {
        this.$router.push({
          path: "/active",
          query: {
            ...this.$store.state.query,
          }
        });
      },
      //查看详情
      toActive(id){
        this.commonApi(36,id)
        this.$router.push({
          path: '/active/'+id,
          query: {
            ...this.$store.state.query,
          }
        });
      },
      getHot() {
        hot().then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.activeList = res.data.data;
        }).catch(err => {
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "a/scss/common";
  @import "a/scss/active/activeList";

  .home-container {
    font-family: "PingFang SC";
  }

  .bannerImg {
    width: 100%;
    min-width: 1150px;
    position: relative;

    ::v-deep .el-carousel__container {
      height: inherit;
      min-height: 450px;

      .el-carousel__arrow {
        width: 50px;
        height: 50px;

        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-size: 20px;
        }
      }
    }

    img {
      /*width: 100%;*/
      height: inherit;
    }

    .search {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      z-index: 1000;
      width: 100%;
      cursor: pointer;

      .search_back {
        position: relative;
        color: #cccccc;
        background-color: #fff;
        padding: 0 30px 0px 56px;
        width: 60%;
        height: 60px;

        &:after {
          content: '';
          position: absolute;
          right: -52px;
          top: 0;
          width: 4px;
          height: 0;
          border-width: 0px 50px 60px 0px;
          border-style: none solid solid;
          border-color: transparent transparent #ffffff;
        }
        .search_input {
          font-size: 18px;
          color: #AFAFAF;
          background-color: #fff;
          height: 54px;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 3px solid transparent;
          border-bottom: 3px solid var(--all_color);
          font-family: "PingFang SC";
        }

        i {
          font-size: 20px;
          font-weight: bold;
          color: #AFAFB0;
        }
      }
    }

  }

  .home_title {
    font-size: 26px;
    margin: 20px 0;
    font-weight: 600;
  }

  .active-list {
    margin-top: -10px;
  }

  .home-container {
    color: #333333;
    font-family: "PingFang SC";
  }

  .home_content {
    padding: 50px;
  }

  .flex-line {
    margin: 15px auto;
    display: flex;
    flex-flow: row;
    align-items: center;

    .line {
      flex: 1;
      border-bottom: 1px solid #eeeeee;
    }

    .view-more {
      color: #999999;
      font-size: 13px;
      width: 74px;
      margin-left: 40px;

      &:hover {
        cursor: pointer;
        color: var(--all_color);
      }
    }
  }

  .reflectionList {
    display: flex;
    flex-flow: wrap;
    font-size: 12px;
    color: #333333;
    /*background: red;*/

    .reflections_item {
      flex-basis: 31.8%;
      margin: 1% 0;
      background-color: #ffffff;
      min-height: 235px;
      position: relative;
      width: 200px;
      border-radius: 4px;
      border: 1px solid #cccccc;
      margin: 1% 2% 1% 0;
      display: flex;
      flex-flow: column;

      &:nth-child(3n+3) {
        margin: 1% 0 1% 0;
      }

      .item_float {
        height: 20px;
        position: absolute;
        top: -1px;
        left: 5%;
        padding: 0 10px;
        background-color: #ffffff;

        .item_float_content {
          display: flex;
          transform: translateY(-50%);
        }

        .item_name {
          line-height: 36px;
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: flex-end;
        }

        .name_line {
          border-bottom: 2px solid var(--all_color);
        }
      }

      .reflections_content {
        margin: 7%;
        flex: 1;
      }

      .tags {
        display: flex;
        padding: 2% 0 3%;
        color: #ffffff;

        :first-child {
          background-color: #FEB370;
        }

        :nth-child(2) {
          background-color: var(--all_color);
        }
      }

      .tag {
        font-size: 13px;
        padding: 5px 9px;
        margin-right: 10px;
        border-radius: 4px;
      }

      .item_text {
        font-size: 13px;
        line-height: 24px;
        text-indent: 2em;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
  }

</style>
