import {request} from './network'

// 热门活动
export function hot(data) {
  return request({
    method: 'get',
    url: '/activityCenter/hot',
    params: data
  })
}
